.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fc-button-primary {
  background-color: #4A1C36!important;
  border-color: #4A1C36!important;
  text-transform: capitalize!important;
  font-size: 12px!important;
}

.smart-scheduler-event-button{
  display: none!important;
}

.smart-scheduler-event::before {
  background-color: rgba(0,0,0,0)!important;
}

.event-gray > .smart-scheduler-event-content {
  background-color: #aaaaaa;
}

.event-gray > .smart-scheduler-event-content:hover {
  background-color: #bbbbbb!important;
}

.event-gray:focus  > .smart-scheduler-event-content{
  background-color: #bbbbbb!important;
}

.event-teal > .smart-scheduler-event-content {
  background-color: #00b300;
}

.event-teal > .smart-scheduler-event-content:hover {
  background-color: #00cc00!important;
}

.event-teal:focus  > .smart-scheduler-event-content{
  background-color: #00cc00!important;
}

.event-red > .smart-scheduler-event-content {
  background-color: lightpink;
}

.event-red > .smart-scheduler-event-content:hover {
  background-color: pink!important;
}

.event-red:focus  > .smart-scheduler-event-content{
  background-color: pink!important;
}

.event-brown > .smart-scheduler-event-content {
  background-color: chocolate;
}

.event-brown > .smart-scheduler-event-content:hover {
  background-color: orange!important;
}

.event-brown:focus  > .smart-scheduler-event-content{
  background-color: orange!important;
}
